.scrollableTextArea {
  overflow-y: auto;
}
#inputField {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: red;
}
.card-description textarea {
  background-color: red;
}
